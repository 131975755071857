.sudoBody {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 6rem;
}

@media (max-width: 1150px) {
  .main {
    padding: 1em;
  }
}

.shimmerAI {
  --bg-size: 400%;
  --color-one: hsl(288 62% 63%);
  --color-two: hsl(344 64% 63%);
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .shimmerAI {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

/* .glowTable {
  animation: spin 4s linear infinite;
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 140px;
  background-color: #160734;
  box-shadow: inset 0 0 50px #fff, inset 20px 0 60px violet,
    inset -20px 0 60px #0ff, inset 20px 0 300px violet, inset -20px 0 300px #0ff,
    0 0 50px #fff, -10px 0 60px violet, 10px 0 60px #0ff;
} */
/* 
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
} */

.glowTable:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.75);
  -webkit-filter: blur(5vw);
  -moz-filter: blur(5vw);
  -ms-filter: blur(5vw);
  filter: blur(5vw);
  background: linear-gradient(270deg, #0fffc1, #7e0fff);
  background-size: 200% 200%;
  animation: animateGlow 10s ease infinite;
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.glowTable {
  position: relative;
  width: 100%;
  height: 15vw;
}
